import {
    makeObservable,
    observable,
    runInAction,
    action,
    computed
} from 'mobx'
import {
    timestampSec,
    convertHMS,
    replace1k,
    replacePayouts,
    buildMediaUrlFromString
} from '../utils/helper';
import GeneralStore from './stores/generalstore';
import BlindStore from './stores/blindstore';
import PayStore from './stores/paystore';
import LayoutStore from './stores/layoutstore';
import EventStore from './stores/eventstore';
import AdStore from './stores/adstore';
import PayoutStore from './stores/payoutstore';
import { EVENTS } from '../constants/events';
import EventManager from './events';

function isMobile() {
    if (process.env.REACT_APP_MOBILE === "true") {
        return true;
    }
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const DEFAULT_WEBSOCKETURL: string = 'wss://websocket.couchgames.wtf/';
const CLOCK_STATUS = {
    NOT_STARTED: 0,
    RUNNING: 9
}
export const CLOCK_STYLEVERSION = {
    VERSION_1: 1
}

// Structure Level
// [0] = bbAnte | 0 = yes | 1 no
// [1] = time 
// [2] = smallBlind
// [3] = bigBlind
// [4] = break | 0 = yes | 1 = no
interface ClockLevel {
    time: number;
    levelDuration: number;
    level: number;
    levelId: number;
    break: boolean;
    ante: number;
    anteNext: number;
    smallBlind: number;
    bigBlind: number;
    nextSmallBlind: number;
    nextBigBlind: number;
    avgStack?: number;
}

interface ClockPauseLevel {
    break: boolean;
    time: number;
    levelDuration: number;
    level: number;
}

const convertLevelData = (data: Array<Array<number>>): Array<ClockLevel> => {
    const lvlArray: Array<ClockLevel> = [];
    let totalTime: number = 0;
    let levelCount: number = 0;

    data.forEach((lvl: any, i: number, arr: any) => {
        const isLevel = lvl[0] === 0;
        const nextLevel = arr.find((nextLvl: any, j: number) => j > i && nextLvl[0] === 0) || null;

        totalTime += lvl[1] * 60;

        if (isLevel) {
            levelCount += 1
        }

        lvlArray.push({
            levelId: levelCount,
            time: totalTime,
            levelDuration: lvl[1] * 60,
            level: i + 1,
            break: !isLevel,
            ante: isLevel ? lvl[4] : 0,
            anteNext: nextLevel?.[4] || 0,
            smallBlind: isLevel ? lvl[2] : 0,
            bigBlind: isLevel ? lvl[3] : 0,
            nextSmallBlind: nextLevel?.[2] || 0,
            nextBigBlind: nextLevel?.[3] || 0,
            avgStack: lvl?.[6] || 0
        });

    })

    return lvlArray;
}

export default class PokerClock {

    private _sdk: any;
    private _events: any;

    public wssConnectionUrl: string;
    public wssConnection: WebSocket | undefined;
    public wssConnectionState: number;

    // Clock property
    public code: string;

    public planConfig: any;
    public pausePlan: Array<ClockPauseLevel>;
    public plan: Array<ClockLevel>;
    public anteType: string;

    public premiumClock: boolean;

    public status: number;
    public elapsed: number;
    public currentLevel: number;
    public currentPauseLevel: number;
    public currentPause: boolean;
    public currentMattLevel: any;
    public currentTime: number;
    public currentTimeElapsed: number;
    public currentTimeNextBreak: number;
    public clockLatestTimestamp: number;
    public clockTimestampDiff: number;
    public h1Label: string | null;
    public h2Label: string | null;
    public formatCurrency: string;
    public formatCountry: string;
    public allowCurrency: boolean;

    public preloadedImages: Map<string, any>;

    // Advertising
    public adData: any;

    // PayOut
    public payouts: any;

    public payoutText: string;
    public payoutRound: number;

    public payoutTextNew: string;

    //PayInfo
    public countPlayer: number;
    public countRebuy: number;
    public countEarlybird: number;
    public countAddon: number;
    public countBusted: number;

    public buyInConfig: Array<number>;
    public rebuyConfig: Array<number>;
    public addonConfig: Array<number>;
    public earlybirdConfig: Array<number>;
    public prizepoolConfig: Array<number>;

    // hints
    public connectHint: boolean | null;

    // Stream
    public streamEnabled: number;
    public streamUrl: string;

    // LookLike
    public styleVersion: number;

    public logo: string;
    public logoRight: string;
    public style: any;

    public clockTimeout: any;
    public admin: string | null;
    public adminEditor: any;

    public isShowMode: boolean;
    public autoChanged: boolean;

    public inited: boolean;

    constructor(sdk: any, clockCode: string, config: any, onlyShowMode: boolean = false) {
        this._sdk = sdk;
        this._events = new EventManager(sdk);

        makeObservable(this, {
            connectHint: observable,
            styleVersion: observable,
            autoChanged: observable,
            status: observable,
            elapsed: observable,
            pausePlan: observable,
            premiumClock: observable,
            streamUrl: observable,
            streamEnabled: observable,
            planConfig: observable,
            currentPauseLevel: observable,
            currentTime: observable,
            adData: observable,
            clockLatestTimestamp: observable,
            currentTimeElapsed: observable,
            anteType: observable,
            style: observable,
            payoutRound: observable,
            currentPause: observable,
            currentMattLevel: observable,
            logo: observable,
            logoRight: observable,
            clockTimestampDiff: observable,
            currentLevel: observable,
            currentTimeNextBreak: observable,
            adminEditor: observable,
            plan: observable,
            code: observable,
            wssConnectionState: observable,
            payouts: observable,
            payoutText: observable,
            payoutTextNew: observable,
            h1Label: observable,
            h2Label: observable,
            allowCurrency: observable,
            formatCurrency: observable,
            formatCountry: observable,
            buyInConfig: observable,
            countPlayer: observable,
            rebuyConfig: observable,
            addonConfig: observable,
            prizepoolConfig: observable,
            inited: observable,
            earlybirdConfig: observable,
            countRebuy: observable,
            countAddon: observable,
            countEarlybird: observable,
            countBusted: observable,
            hideConnectHint: action,
            showConnectHint: action,
            closeAdmin: action,
            changePayOutText: action,
            updateClockData: action,
            updateTimer: action,
            currentBlinds: computed,
            currentBlindsInK: computed,
            timeString: computed,
            timeBreakString: computed,
            timeNextBreak: computed,
            timeNextBreakOneLine: computed,
            advertising: computed,
            advertisingImage: computed,
            advertisingAnimation: computed,
            nextAnte: computed,
            nextBlinds: computed,
            nextBlindsWithK: computed,
            nextBreak: computed,
            break: computed,
            displayNextAverageStack: computed,
            currentTimestamp: computed,
            payoutDifferent: computed,
            bodyStyle: computed,
            connected: computed,
            displayPlayer: computed,
            displayPlayerHorizontal: computed,
            displayEarlyBird: computed,
            chipsInPlay: computed,
            pricePool: computed,
            displayChipsInPlay: computed,
            displayCurrentLevel: computed,
            displayCurrentLevelShort: computed,
            displayPricePool: computed,
            displayAverageStack: computed,
            displayAnte: computed,
            currentAnte: computed,
            currentAnteType: computed,
            displayPayout: computed,
            displayNextBlinds: computed,
            displayNextBlindsWithKFormat: computed,
            totalEntries: computed,
            currentLevelId: computed,
            currentLevelArrayId: computed,
            activePlayer: computed,
            averageStack: computed,
            isMattMode: computed,
            nextAvgStack: computed,
            authtoken: computed,
            displayReEntry: computed,
            displayAddon: computed,
            showBuyIn: computed,
            showEarlyBird: computed,
            showAddOn: computed,
            showRebuy: computed,
            showPlayer: computed,
            showPricePool: computed,
            showPayout: computed,
            hasBreak: computed,
            isCurrencyDisabled: computed,
            tournamentStarted: computed,
            running: computed
        })

        this.code = clockCode;
        this.wssConnectionState = 0;
        this.clockTimeout = null;
        this.payoutText = '';
        this.logo = '';
        this.streamEnabled = 0;
        this.streamUrl = '';
        this.logoRight = './data/logo.png';
        this.payoutTextNew = '';
        this.wssConnectionUrl = config?.url || DEFAULT_WEBSOCKETURL;
        this.style = {};
        this.isShowMode = onlyShowMode;
        this.styleVersion = CLOCK_STYLEVERSION.VERSION_1;
        this.pausePlan = [];
        this.planConfig = {};
        this.connectHint = null;
        this.preloadedImages = new Map();
        this.connect(0);

        this.admin = config?.auth || null;
        this.adminEditor = null; // Is an editor active?
        this.inited = false;
        this.currentPause = false;
        this.currentMattLevel = null;
        this.status = 0;
        this.plan = [];
        this.payouts = [];
        this.elapsed = 0;
        this.currentTimeElapsed = 0;
        this.currentLevel = 1;
        this.currentPauseLevel = 1;
        this.currentTime = 0;
        this.currentTimeNextBreak = 0;
        this.countPlayer = 0;
        this.countBusted = 0;
        this.countRebuy = 0;
        this.countAddon = 0;
        this.countEarlybird = 0;
        this.h1Label = null;
        this.h2Label = null;
        this.anteType = 'bb';
        this.formatCountry = 'DE';
        this.formatCurrency = 'EUR';
        this.payoutRound = 10;
        this.allowCurrency = true;
        this.premiumClock = false;

        this.buyInConfig = [0, 0, 0];
        this.rebuyConfig = [0, 0, 0];
        this.addonConfig = [0, 0, 0];
        this.earlybirdConfig = [0, 0, 0];
        this.prizepoolConfig = [0, 0];

        this.adData = null;

        // Timestamp
        this.clockLatestTimestamp = timestampSec();
        this.clockTimestampDiff = 0;

        this.autoChanged = false;
    }

    get currentLevelArrayId() {
        if (this.plan.length >= this.currentLevel) {
            return this.plan[this.currentLevel - 1].level
        }
        return 1;
    }

    get authtoken() {
        return btoa(this.admin || '');
    }

    get returnToAdmin() {
        return this.isShowMode === false;
    }

    get payoutDifferent() {
        return this.payoutText !== this.payoutTextNew;
    }

    get bodyStyle() {
        return (this.style?.body?.bgColor || this.style?.body?.bgImage) ? {
            ...(this.style?.body?.bgColor ? { backgroundColor: this.style?.body?.bgColor } : {}),
            ...(this.style?.body?.bgImage ? {
                bgImage: this.style?.body?.bgImage ? `url(${this.style?.body?.bgImage})` : null,
                bgImageSize: 'cover',
                bgRepeat: 'no-repeat'
            } : {}),
        } : null
    }

    get hasBreak() {
        if (this.isMattMode) {
            return !!this.pausePlan.length;
        }
        return this.plan?.find((lvl: any) => lvl.break === true) || false;
    }

    get isCurrencyDisabled() {
        return this.allowCurrency === false || this.style?.flags?.disableCurrency;
    }

    changePayOutText(value: string) {
        this.payoutTextNew = value;
    }

    formatToCurrency(value: number, maximumFractionDigits: number = 2): string {
        if (this.isCurrencyDisabled) {
            return this.formatToDecimal(value, maximumFractionDigits)
        }
        return new Intl.NumberFormat(`${this.formatCountry}-${this.formatCountry}`, { style: "currency", currency: this.formatCurrency, maximumFractionDigits }).format(value)
    }

    formatToDecimal(value: number, maximumFractionDigits: number = 2): string {
        return new Intl.NumberFormat(`${this.formatCountry}-${this.formatCountry}`, { maximumFractionDigits }).format(value)
    }

    updateClockData(data: any): void {
        const newPlan = convertLevelData(data?.config?.level?.data || []);
        const newPlanConfig = {
            mattMode: data?.config?.level?.mattMode || false,
            pause: data?.config?.level?.pause || []
        }

        this.premiumClock = data?.premium === true;

        if (this.connectHint === null) {
            // if(!this.premiumClock && this.connectHint === null) {
            this.connectHint = true;
        }

        // has the plan change?
        if (JSON.stringify(newPlan) !== JSON.stringify(this.plan) || JSON.stringify(newPlanConfig) !== JSON.stringify(this.planConfig)) {
            this.plan = convertLevelData(data?.config?.level?.data || []);
            let totalTime2: number = 0;

            // Generate a new array
            const newArray: Array<ClockPauseLevel> = [];

            newPlanConfig.pause.forEach((lvl: any, i: number) => {

                newArray.push({
                    break: false,
                    time: totalTime2 + lvl[1] * 60,
                    levelDuration: lvl[1] * 60,
                    level: (i * 2) + 1
                });

                // the real pause
                newArray.push({
                    break: true,
                    time: totalTime2 + (lvl[1] * 60 + lvl[0] * 60),
                    levelDuration: lvl[0] * 60,
                    level: (i * 2) + 2
                });

                totalTime2 += lvl[1] * 60 + lvl[0] * 60;
            });

            this.pausePlan = newArray;
            this.planConfig = newPlanConfig
        }

        this.status = data?.clock?.status || 0;
        this.elapsed = data?.clock?.elapsed || 0;
        this.anteType = 'custom';

        if (data?.config?.level?.anteType === 1) this.anteType = 'sb';
        if (data?.config?.level?.anteType === 2) this.anteType = 'bb';

        this.h1Label = data?.config?.lblH1 || null;
        this.h2Label = data?.config?.lblH2 || null;

        this.streamEnabled = data?.config?.stream?.[0] || 0;
        this.streamUrl = data?.config?.stream?.[1] || '';

        this.payouts = data?.config?.payouts || [];
        this.formatCountry = data?.config?.country || 'DE';
        this.formatCurrency = data?.config?.currency || 'EUR';

        this.allowCurrency = data?.config?.flagCurrency === 0 ? false : true;

        this.clockLatestTimestamp = data?.clock?.current || timestampSec();
        this.clockTimestampDiff = timestampSec() - (data?.serverTimestamp || timestampSec());

        this.inited = true;

        if (this.payoutText !== (data?.payoutString || '')) {
            this.payoutTextNew = data?.payoutString || '';
        }
        this.payoutText = data?.config?.payoutString || '';
        this.payoutRound = data?.config?.payoutRound || 10;

        this.countPlayer = data?.layer?.main?.player || 0;
        this.countBusted = data?.layer?.main?.busted || 0;
        this.countRebuy = data?.layer?.main?.rebuy || 0;
        this.countAddon = data?.layer?.main?.addon || 0;
        this.countEarlybird = data?.layer?.main?.early || 0;

        this.logo = data?.config?.logo || data?.style?.logo || null;
        this.logoRight = data?.style?.logoRight === undefined ? './data/logo.png' : data.style.logoRight;

        this.buyInConfig = data?.config?.buyin || [0, 0, 0];
        this.rebuyConfig = data?.config?.rebuy || [0, 0, 0];
        this.addonConfig = data?.config?.addon || [0, 0, 0];
        this.prizepoolConfig = data?.config?.prize || [0, 0];
        this.earlybirdConfig = data?.config?.earlybird || [0, 0, 0];
        this.style = data?.style || {};

        this._events.updateConfig(data?.config?.events || []);

        this.adData = data?.config?.ads || null;

        // Update the current timer
        this.updateTimer();
        this.preload(this.adData);

        // Try to kick the client if the device is not supported and latestDevice is given
        if (!this.isShowMode && data?.latestDevice && data?.latestDevice !== this._sdk.user?.deviceId) {
            this._sdk.closeClock(true);
            this._sdk.leaveAdmin();
        } else {
            if (data?.trigger === 'showmode') {
                if (!this.isShowMode && !isMobile() && !this.autoChanged) {
                    this.autoChanged = true;
                    this._sdk.loadClock(this.code, this)
                }
            }
        }
    }

    public preload(ads: any): void {
        if (ads.enabled === false) {
            return;
        }

        // try to preload
        (ads?.list || []).forEach((ad: any) => {
            if (ad?.[1] === 1) {
                if (this.preloadedImages.has(ad[0])) {
                    return;
                }

                let img = new Image();
                img.src = buildMediaUrlFromString(ad[0]);

                this.preloadedImages.set(ad[0], true)
            }
        });
    }

    get currentTimestamp() {
        return timestampSec() - this.clockTimestampDiff;
    }


    get isMattMode() {
        return this.planConfig.mattMode || false;
    }

    // Update the current timer
    public updateTimer(forceUpdate = false, latestLevel: any = null): void {

        let lookForLevel = undefined;
        let lookForBreak = undefined;
        let elapsedTime = 0;
        let missingTimeToNextLevel = 0;
        const wasPause = this.currentPause;
        let wasLevel = this.currentMattLevel;

        // Calculate the elapsed time
        if (this.running === false) {
            elapsedTime = this.elapsed;
        } else {
            // Calculate the elapsed time based on the timestamp
            elapsedTime = (this.currentTimestamp - this.clockLatestTimestamp) + this.elapsed;
        }

        this.currentTimeElapsed = elapsedTime;

        // => Es wird keine Pause gefunden, wenn der Matt Modus an ist, da alle Level = keine Pause sind 
        // lookForBreak = this.nextBreak; // lookForBreak = undefined;
        // Look for the current level
        if (this.isMattMode) {
            const lookForCurrentBreak = (this.pausePlan || []).find((level: any) => {
                if (elapsedTime <= level.time) {
                    return level;
                }
                return undefined;
            });

            this.currentPause = !!lookForCurrentBreak?.break;

            lookForBreak = (this.pausePlan || []).find((level: any) => {
                if (elapsedTime <= level.time && level.break) {
                    return level;
                }
                return undefined;
            })
            lookForLevel = (this.plan || []).find((level: any, i: any) => {
                if (this.averageStack < level.avgStack) {
                    return level;
                }
                return undefined;
            })
            this.currentMattLevel = lookForLevel;

        } else {
            lookForBreak = this.nextBreak;
            lookForLevel = (this.plan || []).find((level: any) => {
                if (elapsedTime <= level.time) {
                    return level;
                }
                return undefined;
            })
        }

        if (!lookForLevel) {
            lookForLevel = this.plan[this.plan.length - 1]
        }

        if (!lookForBreak) {
            lookForBreak = {
                time: elapsedTime || 0,
                levelDuration: 0
            }
        }

        if (this.isMattMode) {
            this.currentTime = lookForBreak.time - elapsedTime;
        } else {
            this.currentTime = lookForLevel.time - elapsedTime;
        }
        this.currentTimeNextBreak = (lookForBreak.time - lookForBreak.levelDuration) - elapsedTime;
        missingTimeToNextLevel = this.isMattMode ? 0 : lookForLevel.time - elapsedTime;

        /**
         * Check for Events
         */
        if (this.isMattMode) {
            // Im Mattmode verhält er sich etwas anders. Es gibt nur BreakEnd, BreakStart und NextLevel
            if (wasPause === true && this.currentPause === false) {
                this._events.trigger(EVENTS.EVENT_BREAK_END);
            } else if (wasPause === false && this.currentPause === true) {
                this._events.trigger(EVENTS.EVENT_BREAK_START);
            } else if (wasLevel) {
                if (wasLevel.level !== this.currentMattLevel?.level) {
                    this._events.trigger(EVENTS.EVENT_NEXTLEVEL);
                }
            }
        } else {
            if (latestLevel !== null && lookForLevel.level > (latestLevel?.level || 1)) {
                if (!lookForLevel.break) {
                    if (latestLevel?.break) {
                        this._events.trigger(EVENTS.EVENT_BREAK_END);
                    } else {
                        this._events.trigger(EVENTS.EVENT_NEXTLEVEL);
                    }
                } else if (lookForLevel.break) {
                    this._events.trigger(EVENTS.EVENT_BREAK_START);
                }
            } else {
                // Report a tick event
                if (missingTimeToNextLevel === 60) {
                    if (!lookForLevel.break) {
                        this._events.trigger(EVENTS.EVENT_NEXTLEVEL_60)
                    }
                } else if (missingTimeToNextLevel === 30) {
                    if (!lookForLevel.break) {
                        this._events.trigger(EVENTS.EVENT_NEXTLEVEL_30)
                    }
                } else if (missingTimeToNextLevel === 10) {
                    if (!lookForLevel.break) {
                        this._events.trigger(EVENTS.EVENT_NEXTLEVEL_10)
                    }
                } else if (missingTimeToNextLevel === 5) {
                    if (!lookForLevel.break) {
                        this._events.trigger(EVENTS.EVENT_NEXTLEVEL_5)
                    }
                }
            }
        }
        // triggr Event!
        // if (this.currentLevel !== lookForLevel.level) {
        // this.triggerNextlevel(lookForLevel)
        // }

        this.currentLevel = lookForLevel.level;

        if (this.isMattMode) {
            this.currentPauseLevel = (lookForBreak?.level || 1) - 1;
            if (this.currentPauseLevel < 1) {
                this.currentPauseLevel = 1;
            }
        }

        //     time: number;
        // levelDuration: number;s
        // level: number;
        // break: boolean;
        // bbAnte: boolean;
        // smallBlind: number;
        // bigBlind: number;

        if (this.currentTime < 0) {
            this.currentTime = 0;
        }

        // @ts-ignore
        if (this.currentTimeNextBreak < 0) {
            this.currentTimeNextBreak = 0;
        }

        // #############################################
        // update the timer
        // #############################################
        if (!this.clockTimeout || forceUpdate) {
            if (this.clockTimeout) {
                clearTimeout(this.clockTimeout);
                this.clockTimeout = null;
            }

            if (this.running) {
                const that = this;
                this.clockTimeout = setTimeout(() => {
                    that.updateTimer(true, that.currentLevelInfo);
                }, 200)
            }
        }
    }

    connect(attempt = 0) {
        let reconnectTime: number = 10000 + (attempt * 2000);
        if (reconnectTime > 30000) {
            reconnectTime = 30000;
        }
        this.wssConnection = new WebSocket(`${this.wssConnectionUrl}?s=tournamentclock&v=1&t=${process.env.REACT_APP_TYPE}&p=${this.code}`);
        this.wssConnection.binaryType = 'blob';
        this.wssConnectionState = 1;

        this.wssConnection.onopen = (event) => {
            runInAction(() => {
                this.wssConnectionState = 2;
            })
        };

        this.wssConnection.onclose = (event) => {
            if (event.code !== 3000) {
                console.log('CONNECTION LOST', event.code)
                runInAction(() => {
                    this.wssConnectionState = 0;
                })
                setTimeout(() => {
                    this.connect(attempt + 1)
                }, reconnectTime)
            } else {
                console.log('closing forced')
            }
        }

        this.wssConnection.onmessage = (event) => this.handleMessage(event ? event.data : undefined);

    }

    disconnect(reason: number = 3000) {
        if (this.wssConnection) {
            this.wssConnection.close(reason);
        }
    }

    handleMessage(message: any) {
        if (!message) return;
        let rawMessage = undefined;

        try {
            rawMessage = JSON.parse(message);
        } catch (e) { }

        // Only handle the message when a type is presented
        if (rawMessage?.type) {
            const type = parseInt(rawMessage.type, 10);

            switch (type) {
                case 2:
                    break;
                case 90:
                    // Update the clockdata
                    this.updateClockData(rawMessage);
                    break;
                case 3:
                    // Kick the client
                    this._sdk.closeClock(true);
                    this._sdk.leaveAdmin();
                    break;
                default:
                    break;
            }
        }
    }



    get connected() {
        return this.wssConnectionState === 2;
    }

    // Display the total player
    get displayPlayer() {
        return `${this.formatToDecimal(this.countPlayer - this.countBusted)} / ${this.formatToDecimal(this.countPlayer)}`
    }

    // Display the total player horizontal
    get displayPlayerHorizontal() {
        return `Player ${this.formatToDecimal(this.countPlayer - this.countBusted)} / ${this.formatToDecimal(this.countPlayer)}`
    }

    get displayEarlyBird() {
        return this.formatToDecimal(this.countEarlybird)
    }

    get displayAddon() {
        return this.formatToDecimal(this.countAddon)
    }

    get displayReEntry() {
        return this.formatToDecimal(this.countRebuy)
    }

    get displayChipsInPlay() {
        return this.formatToDecimal(this.chipsInPlay)
    }

    get displayAnte() {
        return this.currentAnte;
    }

    get displayNextAverageStack() {
        if (this.isMattMode) {
            // @ts-ignore
            return this.formatToDecimal(this.nextAvgStack, 0)
        }
        return 0;
    }

    get displayAverageStack() {
        return this.formatToDecimal(this.averageStack, 0)
    }

    get displayPricePool() {
        return this.formatToCurrency(this.pricePool, 0)
    }

    get displayNextBlinds() {
        if (this.style?.layout?.nextBlindsWithAnte === true) {
            return `Next Blinds: ${this.nextBlinds}${this.nextAnte}`
        }
        return `Next Blinds: ${this.nextBlinds}`
    }

    get displayNextBlindsWithKFormat() {
        if (this.style?.layout?.nextBlindsWithAnte === true) {
            return `Next Blinds: ${this.nextBlindsWithK}${replace1k(this.nextAnte)}`
        }
        return `Next Blinds: ${this.nextBlindsWithK}`
    }

    get displayCurrentLevel() {
        return `Level ${this.formatToDecimal(this.currentLevelId)}`
    }

    get displayCurrentLevelShort() {
        return `LVL ${this.formatToDecimal(this.currentLevelId)}`
    }

    /**
     * Optical Getter
     */
    get currentBlinds() {
        if (this.plan.length >= this.currentLevel) {
            return `${this.formatToDecimal(this.plan[this.currentLevel - 1].smallBlind)} / ${this.formatToDecimal(this.plan[this.currentLevel - 1].bigBlind)}`
        }
        return '- / -'
    }

    get currentBlindsInK() {
        if (this.plan.length >= this.currentLevel) {
            return `${replace1k(this.plan[this.currentLevel - 1].smallBlind)} / ${replace1k(this.plan[this.currentLevel - 1].bigBlind)}`
        }
        return '- / -'
    }

    get nextAnte() {
        if (this.plan.length >= this.currentLevel) {
            if (this.plan[this.currentLevel - 1].anteNext) {
                return ` (${this.formatToDecimal(this.anteType === 'sb' ? this.plan[this.currentLevel - 1].anteNext : this.plan[this.currentLevel - 1].anteNext)})`
            }
        }
        return ''
    }

    get nextAvgStack() {
        if (this.plan.length >= this.currentLevel) {
            if (this.plan[this.currentLevel - 1].avgStack) {
                //@ts-ignore
                return this.plan[this.currentLevel - 1].avgStack;
            }
        }
        return ''
    }

    get nextBlinds() {
        if (this.plan.length >= this.currentLevel) {
            return `${this.formatToDecimal(this.plan[this.currentLevel - 1].nextSmallBlind)} / ${this.formatToDecimal(this.plan[this.currentLevel - 1].nextBigBlind)}`
        }
        return '- / -'
    }

    get nextBlindsWithK() {
        if (this.plan.length >= this.currentLevel) {
            return `${replace1k(this.plan[this.currentLevel - 1].nextSmallBlind)} / ${replace1k(this.plan[this.currentLevel - 1].nextBigBlind)}`
        }
        return '- / -'
    }

    get displayPayout() {

        // We have no payouts, return nothing
        if (!this.payouts.length) {
            return '';
        }

        const totalEntries: number = this.totalEntries;
        let usePayoutText = '';

        if (this.payouts.length === 1) {
            usePayoutText = this.payouts?.[0]?.[1] || '';
        } else {
            // We have multiple level, find the next level
            const searchLevel = this.payouts.slice().reverse().find(([entries, text]: any) => totalEntries >= entries)

            if (searchLevel) {
                usePayoutText = searchLevel[1];
            }
        }

        if (usePayoutText) {
            return replacePayouts(usePayoutText, this.pricePool, this.payoutRound, (x: any) => this.formatToCurrency(parseInt(`${x}`, 10), 0));
        }
        return '';
    }

    get currentAnteType() {
        if (this.plan.length >= this.currentLevel) {
            if (this.plan[this.currentLevel - 1].ante) {
                if (this.anteType === 'sb') {
                    return `SB Ante`;
                } else if (this.anteType === 'bb') {
                    return `BB Ante`;
                }
                return `Ante`;
            }
        }

        return ''
    }

    get currentAnte() {
        if (this.plan.length >= this.currentLevel) {
            if (this.plan[this.currentLevel - 1].ante) {
                if (this.anteType === 'sb') {
                    return `SB Ante ${this.plan[this.currentLevel - 1].ante}`;
                } else if (this.anteType === 'bb') {
                    return `BB Ante ${this.plan[this.currentLevel - 1].ante}`;
                }
                return `Ante ${this.plan[this.currentLevel - 1].ante}`;
            }
        }

        return ''
    }

    get showBuyIn() {
        return this.buyInConfig[0] === 1;
    }

    get showEarlyBird() {
        return this.earlybirdConfig[0] === 1;
    }

    get showPlayer() {
        return this.countPlayer > 0;
    }

    get showPricePool() {
        return this.pricePool > 0;
    }

    get showPayout() {
        return this.displayPayout !== '';
    }

    get showAddOn() {
        return this.addonConfig[0] === 1;
    }

    get showRebuy() {
        return this.rebuyConfig[0] === 1;
    }

    get totalEntries() {
        return this.countPlayer + this.countRebuy;
    }

    get chipsInPlay() {
        return (this.countPlayer * this.buyInConfig[2] * this.buyInConfig[0]) +
            (this.countRebuy * this.rebuyConfig[2] * this.rebuyConfig[0]) +
            (this.countEarlybird * this.earlybirdConfig[2] * this.earlybirdConfig[0]) +
            (this.countAddon * this.addonConfig[2] * this.addonConfig[0]);
    }

    get activePlayer() {
        return this.countPlayer - this.countBusted;
    }

    get pricePool() {
        const calcedPrizePool = (this.countPlayer * this.buyInConfig[1] * this.buyInConfig[0]) +
            (this.countRebuy * this.rebuyConfig[1] * this.rebuyConfig[0]) +
            (this.countEarlybird * this.earlybirdConfig[1] * this.earlybirdConfig[0]) +
            (this.countAddon * this.addonConfig[1] * this.addonConfig[0]);

        if (this.prizepoolConfig[0] === 1) {
            if (this.prizepoolConfig[1] > 0 && this.prizepoolConfig[1] > calcedPrizePool) {
                return this.prizepoolConfig[1];
            }
        }

        return calcedPrizePool;
    }

    get averageStack() {
        if (this.activePlayer === 0) {
            return 0;
        }

        return this.chipsInPlay / this.activePlayer;
    }

    get timeString(): string {
        return `${convertHMS(`${this.currentTime}`, 'mm:ss')}`
    }

    get timeBreakString(): string {
        return `Break - ${convertHMS(`${this.currentTime}`, 'mm:ss')}`
    }

    get timeNextBreak(): string {
        const converted = `${convertHMS(`${this.currentTimeNextBreak}`, 'hh:mm:ss')}`;

        return converted === '00:00:00' ? '-' : converted
    }

    get timeNextBreakOneLine(): string {
        return `Break in ${convertHMS(`${this.currentTimeNextBreak}`, 'hh:mm:ss')}`
    }

    get tournamentStarted() {
        return this.status !== CLOCK_STATUS.NOT_STARTED;
    }

    get running() {
        return this.status === CLOCK_STATUS.RUNNING;
    }

    get break() {
        if (this.isMattMode) {
            return this.currentPause;
        } else {
            if (this.plan.length >= this.currentLevel) {
                if (this.plan[this.currentLevel - 1].break) {
                    return true;
                }
            }
        }
        return false;
    }

    get currentLevelInfo() {
        if (!this.isMattMode) {
            if (this.plan.length >= this.currentLevel) {
                return this.plan[this.currentLevel - 1]
            }
        }
        return 1;
    }

    get currentLevelId() {
        if (this.plan.length >= this.currentLevel) {
            return this.plan[this.currentLevel - 1].levelId
        }
        return 1;
    }

    get nextBreak() {

        if (!this.isMattMode) {
            if (this.plan.length > this.currentLevel) {
                if (!this.plan[this.currentLevel - 1].break) {
                    // Look for the next break
                    const findNextBreak = this.plan.find((lvl: any) => {
                        if (lvl.level > this.currentLevel) {
                            if (lvl.break) {
                                return lvl;
                            }
                        }
                        return false;
                    })

                    return findNextBreak;
                }
            }
        }

        return null;
    }

    get advertising() {
        return this.adData?.enable === 1;
    }

    // Get the current advertising image
    get advertisingImage() {
        const adImages = (this.adData?.list || []).filter((ad: any) => Array.isArray(ad)).filter(([_, active]: any) => active === 1).map(([ad, _]: any) => ad);
        const adTimer = this.adData?.time || 10;
        if (adImages.length === 1) {
            return adImages[0];
        } else if (adImages.length > 1) {
            const adIndex: number = (Math.round(this.currentTimeElapsed / adTimer) % adImages.length)
            return adImages[adIndex];
        }
        return null;
    }

    get advertisingAnimation() {
        const adTimer = this.adData?.time || 10;
        return Math.round(this.currentTimeElapsed / adTimer) % 2;
    }

    /**
     * Admin function
     */

    public startTournament(): void {
        if (this.admin) {
            this._sdk.fetchApiOrClose({
                action: 'start',
                code: this.code,
                authcode: this.admin
            }, 'PATCH')
        }
    }

    public pauseClock(): void {
        if (this.admin) {
            this._sdk.fetchApiOrClose({
                action: 'pause',
                code: this.code,
                authcode: this.admin
            }, 'PATCH')
        }
    }

    public resetTime(): void {
        if (this.admin) {
            this._sdk.fetchApiOrClose({
                action: 'resetclocktime',
                code: this.code,
                authcode: this.admin
            }, 'PATCH')
        }
    }

    public resumeClock(): void {
        if (this.admin) {
            this._sdk.fetchApiOrClose({
                action: 'resume',
                code: this.code,
                authcode: this.admin
            }, 'PATCH')
        }
    }


    public skipTimeClock(time: number): void {
        if (this.admin) {
            this._sdk.fetchApiOrClose({
                action: 'timeskip',
                code: this.code,
                authcode: this.admin,
                value: time
            }, 'PATCH')
        }
    }

    public updatePayout(): void {
        if (this.admin) {
            this._sdk.fetchApiOrClose({
                action: 'payoutstring',
                clockCode: this.code,
                auth: this.admin,
                options: {
                    payoutString: this.payoutTextNew
                }
            })
        }
    }

    // playercount, bustedcount, rebuycount
    public changeCount(action: string, subaction: string, value: null | number): void {
        if (this.admin) {
            this._sdk.fetchApiOrClose({
                action,
                subaction,
                value,
                code: this.code,
                authcode: this.admin,
                layer: 'main' // Default layer could be changed later
            }, 'PATCH')
        }
    }

    /**
     * Request to reset the current tournament
     */
    public async resetTournament(): Promise<any> {
        if (this.admin) {
            this._sdk.fetchApiOrClose({
                action: 'stoptournament',
                code: this.code,
                authcode: this.admin
            }, 'PATCH')
        }
    }

    /**
     * Request to edit the general settings
     * -> 1.) Check if allowed and if yes, get the current editor object
     */
    public async editGeneral(): Promise<any> {
        if (this.admin) {
            const adminRequest = await this._sdk.fetchApi({
                action: 'openclocksetting',
                code: this.code,
                authcode: this.admin,
                options: {
                    settingId: 'general'
                }
            }, 'PATCH')

            if (adminRequest?.status === 200) {
                runInAction(() => {
                    this.adminEditor = new GeneralStore(true);
                    this.adminEditor.load(adminRequest.json.clock, this.admin);
                })
            }
        }
    }

    public async editLayout(): Promise<any> {
        if (this.admin) {
            const adminRequest = await this._sdk.fetchApi({
                action: 'openclocksetting',
                code: this.code,
                authcode: this.admin,
                options: {
                    settingId: 'layout'
                }
            }, 'PATCH')

            if (adminRequest?.status === 200) {
                runInAction(() => {
                    this.adminEditor = new LayoutStore();
                    this.adminEditor.load(adminRequest.json.clock, this.admin);
                })
            }
        }
    }

    public async editBlindlevel(): Promise<any> {
        if (this.admin) {
            const adminRequest = await this._sdk.fetchApi({
                action: 'openclocksetting',
                code: this.code,
                authcode: this.admin,
                options: {
                    settingId: 'blind'
                }
            }, 'PATCH')

            if (adminRequest?.status === 200) {
                runInAction(() => {
                    this.adminEditor = new BlindStore(true);
                    this.adminEditor.load(adminRequest.json.clock, this.admin);
                })
            }
        }
    }
    public async editAds(): Promise<any> {
        if (this.admin) {
            const adminRequest = await this._sdk.fetchApi({
                action: 'openclocksetting',
                code: this.code,
                authcode: this.admin,
                options: {
                    settingId: 'admanager'
                }
            }, 'PATCH')

            if (adminRequest?.status === 200) {
                runInAction(() => {
                    this.adminEditor = new AdStore(this._sdk, true);
                    this.adminEditor.load(adminRequest.json.clock, this.admin);
                })
            }
        }
    }
    public async editEvent(): Promise<any> {
        if (this.admin) {
            const adminRequest = await this._sdk.fetchApi({
                action: 'openclocksetting',
                code: this.code,
                authcode: this.admin,
                options: {
                    settingId: 'event'
                }
            }, 'PATCH')

            if (adminRequest?.status === 200) {
                runInAction(() => {
                    this.adminEditor = new EventStore(true);
                    this.adminEditor.load(adminRequest.json.clock, this.admin);
                })
            }
        }
    }

    public async editPay(): Promise<any> {
        if (this.admin) {
            const adminRequest = await this._sdk.fetchApi({
                action: 'openclocksetting',
                code: this.code,
                authcode: this.admin,
                options: {
                    settingId: 'pay'
                }
            }, 'PATCH')

            if (adminRequest?.status === 200) {
                runInAction(() => {
                    this.adminEditor = new PayStore(true);
                    this.adminEditor.load(adminRequest.json.clock, this.admin);
                })
            }
        }
    }

    public async editPayout(): Promise<any> {
        if (this.admin) {
            const adminRequest = await this._sdk.fetchApi({
                action: 'openclocksetting',
                code: this.code,
                authcode: this.admin,
                options: {
                    settingId: 'payout'
                }
            }, 'PATCH')

            if (adminRequest?.status === 200) {
                runInAction(() => {
                    this.adminEditor = new PayoutStore(true);
                    this.adminEditor.load(adminRequest.json.clock, this.admin);
                })
            }
        }
    }

    public closeAdmin(): void {
        this.adminEditor = null;
    }

    /**
     * The store should be synced with the backend. If finished the admin should be closed
     */
    public async saveAdmin(store: any): Promise<void> {
        if (store) {
            if (this.admin) {
                const adminUpdateRequest = store?.updateAdminRequest ?
                    await store.updateAdminRequest(this._sdk, this.code, this.admin)
                    : await this._sdk.fetchApi({
                        action: 'updateclock',
                        code: this.code,
                        authcode: this.admin,
                        value: store.updatedConfig
                    }, 'PATCH')
                if (adminUpdateRequest?.status === 200) {
                    this.closeAdmin();
                }
            }
        }
    }

    public destroy(): void {
        // Destroy timeout
        if (this.clockTimeout) {
            clearTimeout(this.clockTimeout);
            this.clockTimeout = null;
        }
        this.disconnect();
    }

    // Resume should disconnect the clock
    public onResume(): void {
        this.disconnect(1006);
    }

    public hideConnectHint(): void {
        this.connectHint = false;
    }

    public showConnectHint(): void {
        this.connectHint = true;
    }
}